@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&family=Roboto:wght@400;500;700&display=swap');

* {
    --primary: #efefef;
    --secondary: #1E1E1E;
    --black: #000;
    --text: #202020;
    --textAlt: #ffff;
    --white: #ffff;
    --linkHover: #838383;
    --buttons: #838383;
    --container-font: 'Roboto', sans-serif;
    --text-font: 'Poppins', sans-serif;
}


.navbar-container {
    font-family: var(--text-font);
    z-index: 99;
    display: flex;
    position: fixed;
    justify-content: space-around;
    align-items: center;
    background-color: var(--primary);
    height: 6.5rem;
    width: 100%;
    box-shadow: 5px 5px 10px rgba(0,0,0, 0.3);
}

.Arcos-Logo-Container > .nav-link > img {
    height: 4.5rem;
    width: 6rem;
}

.nav-links {
    display: none;
}

.toggle-button {
    padding: .5rem;
    background-color: var(--secondary);
    border: none;
    margin-left: 2rem;
}

.toggle-button.active {
    display: none;
}

.exit-button {
    display: none;
    background-color: var(--secondary);
    border: none;
    margin-left: 2rem;
    padding: .5rem .6rem;
}

.exit-button.active {
    display: flex;
}

@keyframes link-animation {
    from {right: -30rem;}
    to {right: 0rem;}
}


.nav-links.active {
    background-color: var(--primary);
    justify-content: center;
    position: fixed;
    top: 6.5rem;
    width: 100%;
    height: calc(100% - 6.5rem);
    display: flex;   
    animation: link-animation .5s ease-out 0s;
    overflow: hidden;
}

.nav-links.active > ul {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    list-style: none; 
    padding-inline-start: 0;
}

.nav-links.active > ul > li {
    padding: 1rem 0;
}

.nav-links.active > ul > li > a{
    text-decoration: none;
    color: var(--text);
    font-size: large;
    font-weight: 700;
}

.request-estimate-container {
    display: none;
}

@media (min-width: 1000px) {
    .navbar-container {       
        justify-content: space-around;
        height: 10rem;
    }

    .nav-links {
        background-color: var(--white);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
    }

    .nav-links > ul {
        display: flex;
        padding-inline-start: 0rem;
        margin: 0;
        border: 1px solid rgba(255, 255, 255, 1);
    }

    .nav-links > ul > li {
        display: flex;
        list-style: none;
        height: 3rem;
    }
    
    .nav-links > ul > li > .active {
        background-color: var(--primary);
        color: var(--text);   
    }

    .nav-links > ul > li > a {
        display: flex;
        text-decoration: none;
        color: var(--text);
        padding: 0rem 2rem;
        font-weight: 400;
        align-items: center;
    } 

    .nav-links > ul > li > a:hover {
        color: var(--linkHover)
    }

    .nav-links > ul > li > a.nav-link {
        height: 100%;
    }

    .toggle-button {
        display: none;
    }

    .Arcos-Logo-Container > .nav-link > img {
        height: 6.6rem;
        width: 10.5rem;
        padding-left: 4em;
    }

    .Arcos-Logo-Container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .request-estimate-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        height: 100%;
        margin-right: 4rem;
    }

    .request-estimate-container > div {
        margin-top: 1rem;
    }

    .request-estimate-container > div > a {
        background-color: var(--secondary);
        text-decoration: none;
        color: var(--primary);
        font-weight: 500;
        padding: 0.5rem 1rem;
        border-radius: 20px;
    }

    .request-estimate-container > div > a:hover {
        background-color: var(--primary);
        color: var(--text);
        border: 3px solid var(--secondary);
        margin: -3px;
    }

    .header-number {
        font-size: 1.2rem;
        font-weight: 500;
        color: var(--text);
    }
}



@media (max-width: 1200px ) {
    .Arcos-Logo-Container > .nav-link > img {
        padding-left: 0;
    }

    .request-estimate-container {
        display: none;
    }
}

