.footer-container {
    font-family: var(--text-font);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.footer-body-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--secondary);
    width: 100%;
}

.footer-content > .footer-left > img {
    padding: 1.8rem 0;
}

.footer-content > div {
    margin: .2rem;
}


.information-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--textAlt)
}

.our-location {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.information-container > div > address {
    margin-top: .3rem;
}

.phone-num-container {
    padding: 1.8rem 0 1.8rem 0;
}

.phone-num-container > a {
    font-size: 1.6rem;
}

.quick-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--textAlt);
}

.quick-links > ul {
    padding-top: .5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
}

.quick-links > ul > li {
    padding: .5rem 0;
}

.quick-links > ul > li > a {
    text-decoration: none;
    color: var(--textAlt);
    font-size: 1.1rem;
}

.quick-links > ul > li > a:hover {
    color: var(--linkHover)
}

.business-hours {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--textAlt);
}

.business-hours > div {
    padding: .5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.copyright {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: .25rem;
}

.review-container {
    display: none;
}

@media (min-width: 800px) {

    .footer-body-container {
        width: 100%;
        padding: 3rem 0 6rem;
    }

    .footer-content {
        display: flex;
        width: 90%;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        font-size: 1.4rem;
    }

    .footer-left {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer-left > img {
        padding: 0rem;
        height: 12rem;
        width: 15rem;
    }

    .information-container > div {
        padding: 0;
    }

    .quick-links > ul > li > a {
        transition: .3s;
    }

    .business-hours {
        padding-bottom: 9.2rem;
    }

    .review-container {
        display: flex;
        margin-right: 10%;
        padding-bottom: 14.5rem;
    }

    .footer-content > .review-container > div > a {
        align-items: center;
        justify-content: center;
        display: flex;
        text-decoration: none;
        color: var(--linkHover);
        font-size: 1.5rem;
        font-weight: 500;
        transition: .3s;
    }

    .footer-content > .review-container > div > a > span {
        padding: 0 .4rem .4rem 0;
    }

    .footer-content > .review-container > div > a:hover {
        color: var(--primary)
    }
}

@media (max-width:1200px) {
    .review-container {
        display: none;
    }
}