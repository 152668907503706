.contact-container {
    background-color: var(--primary);
    position: relative;
    top: 10rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 10rem;
    overflow-x: hidden;
}

.page-contact {
    overflow-x: hidden;
}


@media (max-width: 800px) {
    .contact-container {
        top: 6rem;
        margin-bottom: 6rem;
    }
}