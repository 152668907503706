.gallery-container {
    background-color: var(--primary);
    position: relative;
    top: 10rem;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    font-family: var(--text-font);
}


.gallery-title {
    width: 100%;
    font-size: 4rem;
    font-weight: 800;
    background-color: var(--black);
    color: var(--primary);
    text-shadow: 2px 1px var(--primary);
    padding: .5rem 1rem 1rem 4rem;
}

.gallery-container > ul {
    list-style-type: none;
    padding-inline-start: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 5%;
}

.gallery-container > ul > li {
    margin: 3%;
}

.gallery-container > ul > li:hover {
    transition: .2s;
    filter: opacity(85%);
}

.gallery-image {
    height: 25rem;
    width: 20rem;
}

.gallery-image > img {
    height: 100%;
    width: 100%;
    object-fit: fill;
}


@media (max-width: 800px) {
    .gallery-container {
        top: 6.5rem;
    }
}