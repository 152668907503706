.contact-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
}

.contact-form-wrapper > img {
    position: absolute; top: 0; left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 0;
}

.contact-form-wrapper > .icon, div , span , form {
    z-index: 1;
}

.contact-form-container {
    display: flex;
    width: 80%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5% 10%;
    background-color: whitesmoke;
    padding: 3%;
}

.icon {
    font-size: 4rem;
    margin-bottom: 1rem;
}

.contact-form-title {
    font-size: 3rem;
    color: var(--text);
    padding: 0 0 1rem;
}

.contact-form-container > span {
    font-size: 1.2rem;
    color: var(--text);
    padding: 0 0 2rem;
}

.contact-form-container > form> fieldset > ul {
    list-style-type: none;
    padding-inline-start: 0;
}

.contact-form-container > form > fieldset > ul > li {
    margin: 1.5rem 0;
}

textarea,
input[type=text] {
    font-family:Arial;
}

.contact-form-container > form > fieldset > ul > li > input {
    height: 2rem;
    width: 100%;
    border: none;
    line-height: 2rem;
}

.contact-form-container > form > fieldset > ul > li > textarea {
    height: 3rem;
    width: 100%;
    border: none;
    resize: none;
}

.contact-form-container > form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.contact-form-container > form > fieldset {
    width: 80%;
    border: none;
}

.contact-form-container > form > button {
    padding: 1.5rem 2rem;
    color: var(--textAlt);
    border: 1px solid transparent;
    background-color: var(--secondary);
    transition: .3s;
    font-weight: 500;
    font-size: 1rem;
}

.contact-form-container > form > button:hover {
    background-color: var(--primary);
    border: 1px solid var(--secondary);
    color: var(--secondary);
    
}


@media (max-width: 800px) {
    .contact-form-title {
        font-size: 1.5rem;
    }

    .contact-form-container > span {
        font-size: 1rem;
        text-align: center;
    }

    .contact-form-container > form > fieldset  {
        width: 90%;
    }
    .contact-form-container > form {
        width: 100%;
    }
}
