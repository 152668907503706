/* ---- MAIN SECTION CSS ---- (besides home-container) */


.home-container {
    background-color: var(--primary);
    position: relative;
    top: 6.5rem;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    font-family: var(--container-font);
}

.home-phone-number {
    background-color: var(--black);
    width: 100%;
    height: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-phone-number > a {
    text-decoration: none;
    color: var(--textAlt);
    font-size: 1.6rem;
    font-weight: 500;
}

.home-main-mobile {
    display: flex;
}

.home-main-mobile-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
}

.home-main-mobile-wrapper > img {
    position: absolute; top: 0; left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: opacity(.3);
}

.home-main-mobile-quote {
    display: flex;
    flex-direction: column;
    padding: 3rem 0;
    font-size: 1.6rem;
    text-shadow: 2px 1px var(--black);
    font-weight: 700;
    z-index: 1;
}

.home-main-mobile-contact-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    filter: opacity(1);
    background-color: var(--black);
    color: var(--white);
    margin: 1rem;
    padding: 1.5rem 1rem 1rem;
    line-height: 1.5rem;
}

.home-main-mobile-contact-wrapper > a {
    background-color: var(--buttons);
    padding: 1.5rem;
    margin-top: 1rem;
    text-decoration: none;
    color: var(--white);
}

.home-main {
    display: none;
}



@media (min-width: 1000px) {
    .home-container {
        top: 10rem;
    }

    .home-phone-number {
        display: none;
    }

    .home-main-mobile {
        display: none;
    }

    .home-main {
        display: flex;
        height: 40rem;
        width: 100%;
        margin-bottom: 2rem;
        justify-content: center;
    }

    .home-main-left {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex: .55;
        z-index: 2;
    }

    .home-main-left-quote {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        font-size: 3.5rem;
        flex: .55;
        font-weight: 800;
        color: var(--black);
    }

    #gray {
        color: gray;
    }

    .home-main-left-contact-quote-container {
        background-color: var(--black);
        color: var(--textAlt);
        flex: .35;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: -7rem;
        z-index: 3;
        max-height: 13rem;
    }

    .home-main-left-contact-container {
        display: flex;
        justify-content: space-between;
        height: 100%;
        align-items: center;
        padding: 0 2.5rem;
    }
    
    .home-main-left-contact-container > a {
        background-color: var(--buttons);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        text-decoration: none;
        color: var(--textAlt);
        max-width: 8rem;
        max-height: 2rem;
    }

    .home-main-left-contact-container > a:hover {
        background-color: var(--white);
        color: var(--black);
        transition: .5s;
    }

    .home-main-left-contact-quote {
        display: flex;
        align-items: center;
        flex: .70;
        font-size: 1.4rem;
    }

    .home-main-right {
        align-items: center;
        display: flex;
        flex: .28; 
        width: 100%;
        height: 100%;
        
    }

    .home-main-right > img {
        width: 90%;
        height: 75%;
        min-width: 20rem;
        min-height: 20rem;
        object-fit: cover;
        border: 20px solid var(--white)
    }
}


@media(max-width: 1550px ) {
    .home-main-right {
        background-color: var(--primary);
    }
}

@media (max-width: 1400px) {
    .home-main-left-quote {
        font-size: 3rem;
    }

    .home-main-left-contact-quote {
        font-size: 1rem;
    }

}


/* ---- END OF MAIN SECTION CSS ----- */
/* ---- GALLERY SECTION CSS ----*/

.home-main-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-main-gallery-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.home-main-gallery-wrapper > img {
    position: absolute; top: 0; left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.home-gallery-icon {
    font-size: 3rem;
    margin: 3rem;
    display: flex;
    z-index: 1;
}

.home-gallery-title {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-gallery-title > span {
    font-size: 4rem;
    color: var(--white);
    z-index: 1;
    margin-bottom: 4rem;
}

.home-gallery-photos {
    display: flex;
    width: 100%;
    z-index: 1;
    margin-bottom: 3rem;
}

.home-gallery-photos > ul {
   display: flex;
   align-items: center;
   justify-content: space-around;
   list-style-type: none;
   padding-inline-start: 0;
}

.home-gallery-photos > ul > li {
    flex: .20;
    background-color: var(--white);
    padding: 1.1%;
}


.home-gallery-photos > ul > li > img {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.home-gallery-button {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: var(--buttons);
    margin-bottom: 3rem;
}

.home-gallery-button > a {
    text-decoration: none;
    padding: 2rem 3rem;
    color: var(--textAlt);
    font-weight: 500;
}

.home-gallery-button > a:hover {
    background-color: var(--white);
    color: var(--black);
    transition: .5s;
}

@media (max-width: 800px)  {
    .home-gallery-photos {
        display: none;
    }

    .home-gallery-title > span {
        font-size: 2.2rem;
    }

}


/* --- END OF GALLERY CSS --- */
/* --- START OF SERVICES CSS --- */

.home-main-services-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3% 5%;
    background-color: var(--white);
}

.home-main-services-left {
    display: flex;
    flex-direction: column;
    flex: .40;
}

.home-main-services-left > div > ul {
    display: flexbox;
}

.home-main-services-right {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: .50;
}

.home-main-services-title {
    font-size: 3rem;
    color: var(--secondary);
    font-weight: 800;
}

.home-main-services-left > div > ul > li {
    font-size: 1.3rem;
    font-weight: 500;
}

.experience-title, .insured-title {
    font-size: 1.4rem;
    font-weight: 700;
}

.home-main-services-right > div {
    margin: 1rem;
}

.home-main-services-right > div > span {
    font-weight: 500;
}


@media (max-width: 800px) {
    .home-main-services-wrapper {
        flex-direction: column;
        padding: 10%;
    }

    .home-main-services-left {
        margin-bottom: 1rem;
    }
}


/* END OF SERVICES CSS */