.about-container {
    background-color: var(--white);
    position: relative;
    top: 10rem;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    font-family: var(--text-font);
}

.about-us , .sobre-nosotros{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5% 10%;
}

.sobre-nosotros {
    flex-direction: row-reverse;
}

.about-us > .about-us-text,  .sobre-nosotros > .sobre-nosotros-text {
    display: flex;
    flex-direction: column;
    margin-right: 3rem;
    flex: .5
}


.sobre-nosotros > .sobre-nosotros-text {
    margin-right: 0rem;
    margin-left: 3rem;
}

.about-us > .about-us-text > .about-us-title , .sobre-nosotros > .sobre-nosotros-text > .sobre-nosotros-title {
    font-size: 3rem;
    font-weight: 800;
    text-shadow: 2px 1px var(--black);
    margin-bottom: 10%;
}

.about-us > .about-us-text > span ,  .sobre-nosotros > .sobre-nosotros-text > span {
    font-size: 1.2rem;
    font-weight: 500;
}

.about-us > img , .sobre-nosotros > img {
    flex: .3;
    object-fit: cover;
    width: 20rem;
    height: 30rem;
    border: 3px solid var(--black)
}



@media (max-width: 800px) {
    .about-container {
        top: 6.5rem;
    }

    .about-us , .sobre-nosotros{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: 0% 10% 4%;
    }

    .about-us > .about-us-text,  .sobre-nosotros > .sobre-nosotros-text {
        margin: 2rem 0;
    }

}