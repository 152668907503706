/* --- START OF SERVICES HEADER-TOP --- */

.services-container {
    background-color: var(--primary);
    position: relative;
    top: 10rem;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.services-top {
    display: flex;
    width: 100%;
    flex:.4;
    margin: 3% 5%;
}

.services-titles {
    display: flex;
    flex-direction: column;
    flex: .5;
    justify-content: center;
}

.services-title {
    display: flex;
    flex-direction: column;
    font-size: 5rem;
    font-weight: 700;
    line-height: 5rem;
}

.services-button {
    margin-top: 4rem;
    display: flex;
    background-color: var(--black);
    padding: 2.5rem 4rem;
}

.services-button > a {
    text-decoration: none;
    color: var(--white);
    background-color: var(--buttons);
    padding: 2rem;
}

.services-button > a:hover {
    background-color: var(--white);
    color: var(--black);
    transition: .3s;
}

#gry {
    color: var(--linkHover);
}
#blk {
    color: var(--black);
    font-weight: 800;
}

.services-top-images {
    display: flex;
    flex-direction: column;
    flex: .35;
    margin-left: -6em;
    z-index: 0;
}

.services-image, .services-image2 {
    display: flex;
    align-items: center;
    height: 30rem;
    width: 100%;
}

.services-image2 {
    justify-content: flex-end;
    z-index: 1;
}

.services-image > img , .services-image2 > img {
    border: 2rem solid var(--white);
    height: 100%;
    width: 70%;
    object-fit: fill;
}


@media (max-width: 800px) {
    .services-container {
        top: 6.5rem;
    }

    .services-top-images {
        display: none;
    }

    .services-top {
        justify-content: center;
        margin: 6% 0 3rem;
    }
    .services-button {
        margin-top: 3rem;
        display: flex;
        align-items: center;
        background-color: var(--black);
        padding: 1.5rem;
    }
    
    .services-button > a {
        text-decoration: none;
        color: var(--white);
        background-color: var(--buttons);
        padding: .5 1rem;
    }
}

/* --- END OF SERVICES HEADER-TOP --- */
/* --- START OF SERVICES DISPLAY-BOTTOM --- */

.services-bottom {
    width: 100%;
}

.services-list-container {
    margin: 5% 3%;
}

.services-list-container > ul  {
    list-style-type: none;
    padding-inline-start: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.services-list-container > ul > li {
    margin: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.service-title {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: .5rem;
}

.service-image {
    border: 3px solid var(--black);
    height: 25rem;
    width: 20rem;
}

.service-image > img {
    height: 100%;
    width: 100%;
    object-fit: fill;
}